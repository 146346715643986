import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import "antd/dist/antd.css";
import { DatePicker } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import { baseURL } from "../backend_url";
import { formatDate, formatDate_withTime } from "../commonFunctions";
// import { MDBDataTable } from 'mdbreact';
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import { Preview } from "@mui/icons-material";
import { MDBDataTable } from "mdbreact";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdbreact";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function DataTable() {
    const [company_id, setcompany_id] = useState("");
    const [columnData, setColumnData] = useState([]);
    const [rowsData, setRowsData] = useState([]);
    const [rateInfoDetails, setRateInfoDetails] = useState([]);
    const [numDropdowns, setNumDropdowns] = useState(1);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");
    const [locationDetail, setLocationDetail] = useState([{}]);
    const [companyId, setCompanyId] = useState("");
    const [competitors, setCompetitors] = useState([]);
    const [competitorsGrid, setCompetitorsGrid] = useState([["", ""]]);
    const [formData, setFormData] = useState({});
    const [status, setStatus] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});
    const [availableCompetitors, setAvailableCompetitors] = useState([{}]);
    const [competitingOption, setCompetitingOption] = useState({ "Compete Against all availabe competitors in Market": false})

    const [isEnabled, setIsEnabled] = useState(false);

    const handleToggle = () => {
        setIsEnabled(!isEnabled); 
    };

    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name === undefined ? value.location_name : value.location_name + " - " + value.location_id.toString().trim().split("-")[1] }
    ));

    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeLocationName = (characterEntered) => {
        setLocationName(characterEntered.value);
    };

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    const [email, setEmail] = useState("");
    const [emailList, setEmailList] = useState([]);

    const handleAddEmail = () => {
        if (email && !emailList.includes(email)) {
            setEmailList([...emailList, email]);
            setEmail(""); // Clear the input field
        }
    };

    const handleRemoveEmail = (removeEmail) => {
        setEmailList(emailList.filter((e) => e !== removeEmail));
    };

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;
            setcompany_id(temp_company_id);
            setLocationName('')
            setLocationId('')
            setLocationDetail([{}])
            setNumDropdowns(1);
            setCompetitorsGrid([["", ""]]);
            //API call for getting data from DB
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    let allTemp = data.res;
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {
                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);
                                }
                            }
                            )
                    }
                });
        }
    }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        setEmailList([])
        setEmail('')
        setIsEnabled(false)
        if (locationName != "") {
        temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
        setLocationId(temp_location_id);

        axios
            .get(baseURL + "/get_automation_weekly_report/" + locationName)
            .then((res) => {

                console.log('res',res)
                if(res.data){
                    console.log('im inside')
                    // setEmailList(res.email_list)
                    setEmailList(res.data.email_list || []);
                    setIsEnabled(res.data.is_weekly_report_enabled)
                }
                // if (Array.isArray(res.data) && res.data.length > 0) {
                //     const automationEnabled = res.data[0]['enabled'] ;
                //     let tetherEnabled = null;
                //     // Iterate through the array to find isTetherChecked
                //     // console.log('automation response',res.data[0])
                //     for (const item of res.data) {
                //         if (item.hasOwnProperty('isTetherChecked')) {
                //             tetherEnabled = item['isTetherChecked'];
                //             break; // Exit the loop once found
                //         }
                //     }
                //     if (tetherEnabled === undefined || tetherEnabled === null || tetherEnabled === '' || tetherEnabled === 'false' || tetherEnabled === false) {
                //         setTetherEnabledForAutomation("No")
                //     } else {
                //         setTetherEnabledForAutomation("Yes")
                //     }
                //     if (automationEnabled === true){
                //         setLocationEnabledForAutomation("Yes")
                //     }else{
                //         setLocationEnabledForAutomation("No")
                //     }
                // }
                
            });

        }
    }, [locationName]);

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);

        if (foundUser.role === 'SuperAdmin') {

            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['RateUpdate_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {

                    setcompanyDetail(data);
                });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!companyName || !locationName) {
            Swal.fire("Please select both Account Name and Location Name.");
            return;
        }

        if (emailList.length === 0) {
            Swal.fire("Please add at least one email ID.");
            return;
        }
        const isWeeklyReportEnabled = isEnabled; 

        const body_payload = {
            account_id: company_id.toString(),
            location_name: locationName,
            location_id: locationId.toString(),
            is_weekly_report_enabled:isWeeklyReportEnabled,
            company_name: companyName,
            email_list: emailList,
            created_by: loggedInUserDetail.userEmail,
            updated_by: loggedInUserDetail.userEmail,
            created_date: new Date(),
            updated_date: new Date()
        };
        console.log('body_payload',body_payload)
        axios.post(baseURL + "/save_automation_weekly_report_info", body_payload).then((res) => {
            Swal.fire({
                position: "middle",
                icon: "success",
                title: "Weekly Report saved Successfully",
                showConfirmButton: false,
                timer: 3000,
            });
        });

        setCompanyName('');
        setLocationName('');
        setEmail('');
        setEmailList([]);
        setIsEnabled(false);
    };



  return (
    <div className="row pt-3">
        <div className="col-12 mx-3">
            {/* Account Name Section */}
            <div className="row pt-3">
                <div className="col-md-2 mb-3">
                    <label htmlFor="company_name" className="form-label">
                        <b>Account Name</b>
                    </label>
                </div>
                <div className="col-md-3 mb-3">
                    <Select
                        value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                        inputId="company_name"
                        name="company_name"
                        options={companyNameOptions}
                        onChange={handleInputChangeCompanyName}
                    />
                </div>
            </div>
            {/* Location Name Section */}
            <div className="row pt-3">
                <div className="col-md-2 mb-3">
                    <label htmlFor="locationName" className="form-label">
                        <b>Location Name</b>
                    </label>
                </div>
                <div className="col-md-3 mb-3">
                    <Select
                        value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                        inputId="locationName"
                        name="locationName"
                        options={locationNameOptions}
                        onChange={handleInputChangeLocationName}
                    />
                </div>
            </div>
            {/* Weekly Report Enabled Toggle */}
            <div className="row pt-3">
                <div className="col-md-2 mb-3">
                    <label htmlFor="weeklyReportSwitch" className="form-label">
                        <b>Weekly Report Enabled</b>
                    </label>
                </div>
                <div className="col-md-3 mb-3 d-flex align-items-center">
                    <label style={{
                        position: "relative",
                        display: "inline-block",
                        width: "50px",
                        height: "25px",
                        marginRight: "10px",
                    }}>
                        <input
                            type="checkbox"
                            id="weeklyReportSwitch"
                            style={{
                                opacity: 0,
                                width: 0,
                                height: 0,
                            }}
                            checked={isEnabled}
                            onChange={handleToggle}
                        />
                        <span style={{
                            position: "absolute",
                            cursor: "pointer",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: isEnabled ? "#4caf50" : "#ccc",
                            transition: "0.4s",
                            borderRadius: "25px",
                        }}>
                            <span style={{
                                position: "absolute",
                                content: '""',
                                height: "18px",
                                width: "18px",
                                left: isEnabled ? "26px" : "4px",
                                bottom: "3.5px",
                                backgroundColor: "white",
                                transition: "0.4s",
                                borderRadius: "50%",
                            }}></span>
                        </span>
                    </label>
                    {/* Display Yes or No */}
                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {isEnabled ? "Yes" : "No"}
                    </span>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-md-2 mb-3">
                    <label htmlFor="userEmails" className="form-label">
                        <b>User Email IDs</b>
                    </label>
                </div>
                <div className="col-md-3 mb-3 d-flex align-items-center">
                    <input
                        type="email"
                        id="userEmails"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                            flex: 1,
                            padding: "8px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            marginRight: "10px",
                            width: "100%", // Ensures it matches the width of other input fields
                        }}
                    />
                    <button
                        onClick={handleAddEmail}
                        style={{
                            padding: "8px 12px",
                            backgroundColor: "#4caf50",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                    >
                        Add
                    </button>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-md-2 mb-3">
                    <label htmlFor="userEmails" className="form-label">
                    </label>
                </div>
                <div className="col-md-3 mb-3 d-flex align-items-center">
                    {emailList.length > 0 ? (
                        <ul style={{ padding: 0, listStyleType: "none", marginLeft: 0}}>
                            {emailList.map((e, index) => (
                                <li
                                    key={index}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "5px",
                                        width: "100%",
                                        gap:20
                                    }}
                                >
                                    <span style={{ flex: 1 }}>{e}</span>
                                    <button
                                        onClick={() => handleRemoveEmail(e)}
                                        style={{
                                            padding: "4px 8px",
                                            backgroundColor: "#f44336",
                                            color: "white",
                                            border: "none",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Remove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p style={{ color: "#888" }}>No email IDs added yet.</p>
                    )}
                </div>
            </div>
        </div>
        {/* Submit Button */}
        <div className="row pt-3">
            <div className="col-md-2 mb-3">
                <label className="form-label">
                </label>
            </div>
            <div className="col-md-3 mb-3">
                <button onClick={handleSubmit} style={{ padding: "8px 12px", marginLeft:'20px', backgroundColor: "#4caf50", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }}>
                    Submit
                </button>
            </div>
        </div>
   </div>
  );
}
