import React, { useState, useEffect, useRef } from 'react'
import { Alert, Button } from "@mui/material";
import Select from "react-select";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import { baseURL } from '../backend_url';
import axios from 'axios';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';

export default function UpdateForm({ accessType }) {

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('-');
    }

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    var arr10 = []
    var arr50 = []
    var arr100 = []

    for (let i = 1; i <= 10; i++) {
        arr10.push(i)
    }

    for (let i = 1; i <= 50; i++) {
        arr50.push(i)
    }

    for (let i = 1; i <= 100; i++) {
        arr100.push(i)
    }

    const [companyDetail, setcompanyDetail] = useState([{}]);
    const [companyName, setCompanyName] = useState("");
    const [company_id, setcompany_id] = useState("");
    const [locationDetail, setLocationDetail] = useState([{}]);
    const [locationName, setLocationName] = useState("");
    const [locationId, setLocationId] = useState("");
    const [listOfExistingCarClasses, setListOfExistingCarClasses] = useState([])

    const [sippCodeList, setSippCodeList] = useState([])
    const [selectedSippCode, setSelectedSippCode] = useState("")
    const [sippCodeDisabled, setSippCodeDisabled] = useState(false)

    const [carMake, setCarMake] = useState('')
    const [carModel, setCarModel] = useState('')
    const [carDescription, setCarDescription] = useState('')
    const [carImage, setCarImage] = useState('')
    const [inventory, setInventory] = useState('')
    const [ceiling, setCeiling] = useState('')
    // const [floor, setFloor] = useState()
    const [seatCount, setSeatCount] = useState('')
    const [doorCount, setDoorCount] = useState('')
    const [luggageCount, setLuggageCount] = useState("")
    const [vehicleSize, setVehicleSize] = useState('')
    const [vehicleCategory, setVehicleCategory] = useState('')
    const [steeringSide, setSteeringSide] = useState('')

    const [mileage, setMileage] = useState('')

    const [validationMsg, setValidationMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const [loggedInUserDetail, setLoggedInUserDetail] = useState({});

    const [CDW, setCDW] = useState("");
    const [TPExcess, setTPExcess] = useState('');
    const [CDWExcess, setCDWExcess] = useState('');
    const [deposit, setDeposit] = useState('');
    const [fleetImage, setFleetImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);

    //API call
    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("user_token");
        const foundUser = JSON.parse(loggedInUser);
        setLoggedInUserDetail(foundUser);

        if (foundUser.role === 'SuperAdmin') {

            axios.get(baseURL + "/get_company_details_for_users")
                .then((res) => {
                    setcompanyDetail(res.data);
                });
        }
        else {
            fetch(baseURL + "/get_company_details_for_users_accessWise/" + foundUser.access['FleetDescription_Account Access'] + "*" + foundUser.account_id + "*" + foundUser.account_type.account_type)
                .then((res) => res.json())
                .then((data) => {

                    setcompanyDetail(data);
                });
        }
    }, []);

    useEffect(() => {
        if (companyDetail && companyName) {
            let obj_company_detail = companyDetail.filter(
                (value) => value.company_name === companyName
            )[0];
            let temp_company_id = obj_company_detail._id;
            let company_type = obj_company_detail.account_type;

            setcompany_id(temp_company_id);

            setLocationName('')
            setLocationId('')

            //API call for getting data from DB
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            fetch(baseURL + "/get_company_location/" + temp_company_id, requestOptions)
                .then((res) => res.json())
                .then((data) => {

                    let allTemp = data.res;
                    if (loggedInUserDetail.role === 'SuperAdmin') {
                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else if (company_type === 'Affiliate' && loggedInUserDetail.account_type.account_type === 'Corporate') {

                        if (allTemp.length > 0) {
                            setLocationDetail(allTemp);
                        }
                    }
                    else {
                        axios.post(baseURL + "/get_individual_user_details", { 'user_email': loggedInUserDetail.userEmail })
                            .then((data) => {
                                if (allTemp.length > 0) {

                                    allTemp = allTemp.filter((val) => val !== undefined && data.data[0].location_allocated[val.location_id]);
                                    setLocationDetail(allTemp);

                                }

                            }
                            )
                    }
                });

            fetch(baseURL + `/get_car_SIPP_codes_from_standard/${temp_company_id}`)
                .then((res) => res.json())
                .then((data) => {
                    if (data.length > 0) {
                        setSippCodeList(data)
                    }
                    else {
                        setSippCodeList([])
                    }
                })
        }
    }, [companyName]);

    useEffect(() => {
        let temp_location_id = ''
        if (locationName && locationDetail) {
            temp_location_id = locationDetail.filter((loc) => (loc.location_name === locationName)).map((val) => val.location_id)
            setLocationId(temp_location_id);
        }

        setSelectedSippCode('')
        setCarMake('')
        setCarModel('')
        setCarDescription('')
        setInventory('')
        setCeiling('')
        // setFloor('')
        setSeatCount('')
        setDoorCount('')
        setLuggageCount("")
        setVehicleSize('')
        setVehicleCategory('')
        setSteeringSide('')
        setSippCodeDisabled(false)

        setMileage('');
        setCDW('');
        setDeposit('');
        setCDWExcess('');
        setTPExcess('');

        // set values for Yup Validations
        setValue('carMake', '')
        setValue('carModel', '')
        setValue('inventory', '')
        setValue('ceiling', '')
        // setValue('floor', '')

        fetch(baseURL + `/get_existing_car_classes/${temp_location_id}`)
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) {
                    setListOfExistingCarClasses(data)
                }
                else {
                    setListOfExistingCarClasses([])
                }
            })
    }, [locationName])

    // Option for company name
    const companyNameOptions = companyDetail.map((value) => (
        { value: value.company_name, label: value.company_name }
    ));

    const handleInputChangeCompanyName = characterEntered => {
        setCompanyName(characterEntered.value);
    };

    // Option for Location name
    const locationNameOptions = locationDetail.map((value) => (
        { value: value.location_name, label: value.location_name }
    ));

    const handleInputChangeLocationName = characterEntered => {
        setLocationName(characterEntered.value);
    };

    // Options for SIPP codes
    const sippCodeOptions = sippCodeList.map((val) => (
        { value: val, label: val }
    ));

    const handleInputChangeSippCode = characterEntered => {
        setSelectedSippCode(characterEntered.value);
    };

    // Options for Seat Count
    const seatCountOptions = arr50.map((val) => (
        { value: val, label: val }
    ));

    const handleInputChangeSeatCount = characterEntered => {
        setSeatCount(characterEntered.value);
    };

    // Options for Door Count
    const doorCountOptions = arr10.map((val) => (
        { value: val, label: val }
    ));

    const handleInputChangeDoorCount = characterEntered => {
        setDoorCount(characterEntered.value);
    };

    const handleInputChangeLuggageCount = (e) => {
        const value = e.target.value;
        if (value === '' || (parseInt(value) >= 0)) {
            setLuggageCount(value);
        }
    };

    // Options for vehicle Size
    const vehicleSizeOptions = arr100.map((val) => (
        { value: val, label: val }
    ));

    const handleInputChangeVehicleSize = characterEntered => {
        setVehicleSize(characterEntered.value);
    };

    // Options for vehicle Category
    const vehicleCategoryOptions = arr100.map((val) => (
        { value: val, label: val }
    ));

    const handleInputChangeVehicleCategory = characterEntered => {
        setVehicleCategory(characterEntered.value);
    };

    const handleInputChangeSteeringSide = characterEntered => {
        setSteeringSide(characterEntered.value);
    };

    // form validation rules 
    const validationSchema = Yup.object().shape({
        carMake: Yup.string()
            .required('Required'),

        carModel: Yup.string()
            .required('Required'),

        inventory: Yup.number()
            .transform((val) => (isNaN(val) ? undefined : val)).required('Required')
            .min(0, "Inventory too small")
            .integer("Invalid")
            .typeError('Invalid Inventory'),

        ceiling: Yup.number()
            .transform((val) => (isNaN(val) ? undefined : val)).required('Required')
            .min(0, "Ceiling Price too small")
            .typeError('Invaid')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setValue, formState: { errors } } = useForm(formOptions);

    // submit data
    const submitHandler = () => {
        setSuccessMsg("");
        setLoading(true);
        setValidationMsg("");

        if (companyName === '') {
            setLoading(false)
            setValidationMsg('Please select a Account Name to proceed!')
            window.scrollTo(0, 0);
        }

        else if (locationName === '') {
            setLoading(false)
            setValidationMsg('Please select a Location Name to proceed!')
            window.scrollTo(0, 0);
        }

        else if (selectedSippCode === '') {
            setLoading(false)
            setValidationMsg('Please select a SIPP code to proceed!')
            window.scrollTo(0, 0);
        }

        else if (seatCount === '') {
            setLoading(false)
            setValidationMsg('Please select Seat Count to proceed!')
            window.scrollTo(0, 0);
        }
        else if (doorCount === '') {
            setLoading(false)
            setValidationMsg('Please select Door Count to proceed!')
            window.scrollTo(0, 0);
        }
        else if (vehicleSize === '') {
            setLoading(false)
            setValidationMsg('Please select vehicle Size to proceed!')
            window.scrollTo(0, 0);
        }
        // else if (!luggageCount) {
        //     setLoading(false)
        //     setValidationMsg('Please enter luggage count to proceed!')
        //     window.scrollTo(0, 0);
        // }
        else if (vehicleCategory === '') {
            setLoading(false)
            setValidationMsg('Please select vehicle Category to proceed!')
            window.scrollTo(0, 0);
        }
        else if (!fleetImage && !sippCodeDisabled) {
            setLoading(false)
            setValidationMsg('Please upload a Fleet Image to proceed!')
            window.scrollTo(0, 0);
        }
        else {
            let formData = new FormData();

            if (fleetImage) {
                formData.append("file", fleetImage);
            }
            formData.append("id", selectedSippCode);
            formData.append("account_id", company_id.toString());
            formData.append("location_id", locationId.toString());
            formData.append("location_name", locationName.toString());
            formData.append("make", carMake);
            formData.append("model", carModel);
            formData.append("seat_count", seatCount);
            formData.append("door_count", doorCount);
            formData.append("luggage_count", luggageCount);
            formData.append("vehicle_size", vehicleSize);
            formData.append("vehicle_category",vehicleCategory);
            formData.append("description", carDescription);
            formData.append("inventory", inventory ? inventory : "");
            formData.append("ceil_price",ceiling ? ceiling : "");
            formData.append("steering_side", steeringSide);
            formData.append("mileage", mileage ? mileage : "");
            formData.append("CDW", CDW ? CDW : "");
            formData.append("deposit", deposit ? deposit : "");
            formData.append("CDWExcess", CDWExcess ? CDWExcess : "");
            formData.append("TPExcess", TPExcess ? TPExcess : "");
            formData.append("last_updated_by", loggedInUserDetail.userName);
            formData.append("last_updated_date", new Date());
            formData.append("edit_flag", sippCodeDisabled);
            formData.append("image_path", fleetImage);

            axios.post(baseURL + '/save_fleet_description', formData)
                .then(resp => {
                    if (resp.data === "Duplicate") {
                        setLoading(false);
                        setValidationMsg("Fleet details not saved. Duplicate Entry not allowed!")
                        window.scrollTo(0, 0);
                    }
                    else {
                        setLoading(false)
                        setSuccessMsg("Fleet Details saved successfully!")
                        setListOfExistingCarClasses(resp.data)
                        window.scrollTo(0, 0);

                        setSelectedSippCode('');
                        setCarMake('')
                        setCarModel('')
                        setSeatCount('')
                        setDoorCount('')
                        setLuggageCount("")
                        setVehicleSize('')
                        setVehicleCategory('')
                        setCarDescription('')
                        setInventory('')
                        setCeiling('')
                        setSteeringSide('')
                        setMileage('')
                        // setFloor('')
                        setCDW('')
                        setDeposit('')
                        setCDWExcess('')
                        setTPExcess('')
                        setFleetImage(null)
                        setImageURL(null)
                        setSippCodeDisabled(false)
                    }
                })
                .catch(error => console.log(error))
        }
    }

    const editCarClass = (i) => {
        Swal.fire({
            title: 'Edit the selected Car Class?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    listOfExistingCarClasses.map((arr, index) => {
                        if (i === index) {
                            setSelectedSippCode(arr['SIPP_code'])
                            setCarMake(arr['make'])
                            setCarModel(arr['model'])
                            setSeatCount(arr['seat_count'])
                            setDoorCount(arr['door_count'])
                            setLuggageCount(arr['luggage_count'] ? arr['luggage_count'] : "")
                            setVehicleSize(arr['vehicle_size'])
                            setVehicleCategory(arr['vehicle_category'])
                            setCarDescription(arr['description'])
                            setInventory(arr['inventory'])
                            setCeiling(arr['ceil'])
                            setSteeringSide(arr['steering_side'])
                            setMileage(arr['mileage'])
                            // setFloor(arr['floor'])
                            setCDW(arr['CDW'])
                            setDeposit(arr['deposit'])
                            setCDWExcess(arr['CDWExcess'])
                            setTPExcess(arr['TPExcess'])
                            setFleetImage(null)
                            setImageURL(baseURL + "/static/fleet_img/" + arr['image_path'])
                            setSippCodeDisabled(true)

                            // set values for Yup Validations
                            setValue('carMake', arr['make'])
                            setValue('carModel', arr['model'])
                            setValue('inventory', arr['inventory'])
                            setValue('ceiling', arr['ceil'])
                            // setValue('floor', arr['floor'])
                        }
                    })
                }
                else if (result.isDenied) {
                    Swal.fire('Car Class not edited!');
                }
            })
    }

    const deleteCarClasses = (i) => {
        Swal.fire({
            title: 'Delete this Car Class?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                let ID = ''
                listOfExistingCarClasses.map((arr1d, index) => {
                    if (i === index) {
                        ID = arr1d['_id']
                    }
                })

                fetch(baseURL + `/delete_car_class/${ID + "___" + loggedInUserDetail.userName}`)
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.length > 0) {
                            setListOfExistingCarClasses(data)
                        }
                        else {
                            setListOfExistingCarClasses([])
                        }
                    })

                setSelectedSippCode('')
                setCarMake('')
                setCarModel('')
                setSeatCount('')
                setDoorCount('')
                setLuggageCount("")
                setVehicleSize('')
                setVehicleCategory('')
                setCarDescription('')
                setInventory('')
                setCeiling('')
                setSteeringSide('')
                setMileage('')
                setCDW('')
                setDeposit('')
                setCDWExcess('')
                setTPExcess('')
                setFleetImage(null)
                setImageURL(null)
                setSippCodeDisabled(false)
            }
            else if (result.isDenied) {
                Swal.fire('Car Class not deleted!');
            }
        })
    }

    const handleImageChange = (e) => {
        if (!(e.target.files[0].name.match(/\.(jpg|jpeg)$/))) {
            Swal.fire("Please select JPG or JPEG image format");
            return;
        }

        setFleetImage(e.target.files[0]);
        setImageURL(URL.createObjectURL(e.target.files[0]))
    }

    const downloadImage = (image_url, image) => {
        saveAs(image_url, image)
    }

    return (
        <>
            <div className="row pt-2">
                <div className="col-12 mx-3">
                    <span style={{ color: '#1E1E1E', fontSize: '21px' }}>
                        {accessType === "View" ? "Select account and location to view fleet setup:" : "Select account and location for fleet setup:"}
                    </span>
                </div>
            </div>

            <form className="row g-3" onSubmit={handleSubmit(submitHandler)}>
                <div className="row pt-5 mx-2">
                    <div className="col-md-10">
                        {
                            validationMsg ?
                                (<Alert severity="error">{validationMsg}!</Alert>) : ""
                        }

                        {successMsg ?
                            (
                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                    {successMsg}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setSuccessMsg('')}></button>
                                </div>
                            )
                            : ""
                        }
                        <div className="row pt-4">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="company_name" className="form-label" style={{ marginTop: 5 }}>
                                    Account Name
                                </label>
                            </div>

                            <div className="col-md-4 mb-3">
                                <Select
                                    value={{ value: companyName, label: (companyName ? companyName : "Select Account Name") }}
                                    inputId="company_name"
                                    name="company_name"
                                    options={companyNameOptions}
                                    onChange={handleInputChangeCompanyName}
                                />
                            </div>

                            <div className="col-md-1 mb-3"></div>

                            <div className="col-md-2 mb-3">
                                {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                    Account ID
                                </label> */}
                            </div>

                            <div className="col-md-2 mb-3">
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    value={company_id}
                                    id="company_id"
                                    disabled
                                /> */}
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-2 mb-3">
                                <label htmlFor="location_name" className="form-label" style={{ marginTop: 5 }}>
                                    Location Name
                                </label>
                            </div>

                            <div className="col-md-4 mb-3">
                                <Select
                                    value={{ value: locationName, label: (locationName ? locationName : "Select Location Name") }}
                                    inputId="location_name"
                                    name="location_name"
                                    options={locationNameOptions}
                                    onChange={handleInputChangeLocationName}
                                />
                            </div>

                            <div className="col-md-1 mb-3"></div>

                            <div className="col-md-2 mb-3">
                                {/* <label htmlFor="company_id" className="form-label" style={{ marginTop: 5 }}>
                                    Location ID
                                </label> */}
                            </div>

                            <div className="col-md-2 mb-3">
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    value={locationId}
                                    id="location_id"
                                    disabled
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
                {!(accessType === 'View') && <>
                    <div className="row pt-4 mx-2">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-2 mb-1">
                                    <strong>Add New -</strong>
                                    <hr style={{ height: '3px' }} />
                                </div>
                            </div>

                            <div className="row pt-2">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-2" style={{ marginTop: 7 }}>
                                            SIPP Code
                                        </div>

                                        <div className="col-md-3">
                                            <Select
                                                value={{ value: selectedSippCode, label: (selectedSippCode ? selectedSippCode : "Select") }}
                                                inputId="sippCOde"
                                                name="sippCOde"
                                                options={sippCodeOptions}
                                                onChange={handleInputChangeSippCode}
                                                isDisabled={sippCodeDisabled}
                                            />
                                        </div>
                                    </div>

                                    <div className="row pt-4">
                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Make
                                        </div>

                                        <div className="col-md-3">
                                            <input type="text" className={`form-control ${errors.carMake ? 'is-invalid' : ''}`} {...register('carMake')} id="carMake" value={carMake} onChange={(e) => setCarMake(e.target.value)} />
                                            <div className="invalid-feedback">{errors.carMake?.message}</div>
                                        </div>

                                        <div className="col-md-1"></div>

                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Seat Count
                                        </div>

                                        <div className="col-md-3">
                                            <Select
                                                value={{ value: seatCount, label: (seatCount ? seatCount : "Select") }}
                                                inputId="seatCount"
                                                name="seatCount"
                                                options={seatCountOptions}
                                                onChange={handleInputChangeSeatCount}
                                            />
                                        </div>
                                    </div>

                                    <div className="row pt-4">
                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Model
                                        </div>

                                        <div className="col-md-3">
                                            <input type="text" className={`form-control ${errors.carModel ? 'is-invalid' : ''}`} {...register('carModel')} id="carModel" value={carModel} onChange={(e) => setCarModel(e.target.value)} />
                                            <div className="invalid-feedback">{errors.carModel?.message}</div>
                                        </div>

                                        <div className="col-md-1"></div>

                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Door Count
                                        </div>

                                        <div className="col-md-3">
                                            <Select
                                                value={{ value: doorCount, label: (doorCount ? doorCount : "Select") }}
                                                inputId="doorCount"
                                                name="doorCount"
                                                options={doorCountOptions}
                                                onChange={handleInputChangeDoorCount}
                                            />
                                        </div>
                                    </div>

                                    <div className="row pt-4">
                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Luggage Count
                                        </div>

                                        <div className="col-md-3">
                                            <input 
                                                type="text" 
                                                min="0" 
                                                className="form-control" 
                                                id="luggageCount" 
                                                value={luggageCount} 
                                                onChange={handleInputChangeLuggageCount} 
                                            />
                                        </div>

                                        <div className="col-md-1"></div>

                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Vehicle Size
                                        </div>

                                        <div className="col-md-3">
                                            <Select
                                                value={{ value: vehicleSize, label: (vehicleSize ? vehicleSize : "Select") }}
                                                inputId="vehicleSize"
                                                name="vehicleSize"
                                                options={vehicleSizeOptions}
                                                onChange={handleInputChangeVehicleSize}
                                            />
                                        </div>
                                    </div>

                                    <div className="row pt-4">
                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Vehicle Category
                                        </div>

                                        <div className="col-md-3">
                                            <Select
                                                value={{ value: vehicleCategory, label: (vehicleCategory ? vehicleCategory : "Select") }}
                                                inputId="vehicleCategory"
                                                name="vehicleCategory"
                                                options={vehicleCategoryOptions}
                                                onChange={handleInputChangeVehicleCategory}
                                            />
                                        </div>
                                    </div>

                                    <div className="row pt-4">
                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Description
                                        </div>

                                        <div className="col-md-6">
                                            <textarea className='form-control' id="description" value={carDescription} onChange={(e) => setCarDescription(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="row pt-4">
                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Inventory
                                        </div>

                                        <div className="col-md-3">
                                            <input type="text" className={`form-control ${errors.inventory ? 'is-invalid' : ''}`} {...register('inventory')} id="inventory" value={inventory} onChange={(e) => setInventory(e.target.value)} />
                                            <div className="invalid-feedback">{errors.inventory?.message}</div>
                                        </div>

                                        <div className="col-md-1"></div>

                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Mileage
                                        </div>

                                        <div className="col-md-3">
                                            <input type="number" min={0} step={'any'} className="form-control" id="mileage" value={mileage} onChange={(e) => setMileage(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="row pt-4">
                                        <div className="col-md-2">
                                            Charges Per KM / Mile
                                        </div>

                                        <div className="col-md-3">
                                            <input type="text" className={`form-control ${errors.ceiling ? 'is-invalid' : ''}`} {...register('ceiling')} id="ceiling" value={ceiling} onChange={(e) => setCeiling(e.target.value)} />
                                            <div className="invalid-feedback">{errors.ceiling?.message}</div>
                                        </div>

                                        {/* <div className="col-md-3" style={{ marginTop: 10 }}>
                                            Floor Price
                                        </div>

                                        <div className="col-md-2">
                                            <input type="text" className={`form-control ${errors.floor ? 'is-invalid' : ''}`} {...register('floor')} id="floor" value={floor} onChange={(e) => setFloor(e.target.value)} />
                                            <div className="invalid-feedback">{errors.floor?.message}</div>
                                        </div> */}

                                        <div className="col-md-1"></div>

                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            CDW without Tax
                                        </div>

                                        <div className="col-md-3">
                                            <input type="number" min={0} step={'0.01'} className="form-control" id="CDW" value={CDW} onChange={(e) => setCDW(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="row pt-4">
                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Steering Side
                                        </div>

                                        <div className="col-md-3">
                                            <Select
                                                value={{ value: steeringSide, label: (steeringSide ? steeringSide : "Select") }}
                                                inputId="steeringSide"
                                                name="steeringSide"
                                                options={[{ label: "Left Hand Drive", value: "Left Hand Drive" }, { label: "Right Hand Drive", value: "Right Hand Drive" }, { label: "Both Available", value: "Both Available" }]}
                                                onChange={handleInputChangeSteeringSide}
                                            />
                                        </div>
                                        <div className="col-md-1"></div>
                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Deposit
                                        </div>
                                        <div className="col-md-3">
                                            <input type="number" min={0} step={'0.01'}  className="form-control" id="deposit" value={deposit} onChange={(e) => setDeposit(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className='row pt-4'>
                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            CDW Excess
                                        </div>
                                        <div className="col-md-3">
                                            <input type="number" min={0} step={'0.01'} className="form-control" id="cdwExcess" value={CDWExcess} onChange={(e) => setCDWExcess(e.target.value)} />
                                        </div>
                                        <div className="col-md-1"></div>

                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            TP Excess 
                                        </div>
                                        <div className="col-md-3">
                                            <input type="number" min={0} step={'0.01'} className="form-control" id="tpExcess" value={TPExcess} onChange={(e) => setTPExcess(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="row pt-4">
                                        <div className="col-md-2" style={{ marginTop: 10 }}>
                                            Upload Fleet Image
                                        </div>
                                        <div className="col-md-3">
                                            <Button variant="contained" component="label">
                                                Choose Image...
                                                <input hidden accept="image/*" type="file" onChange={(e) => handleImageChange(e)}/>
                                            </Button>
                                        </div>
                                    </div>

                                    {imageURL && (
                                        <div className="row pt-4">
                                            <div className="col-md-2" style={{ marginTop: 10 }}>
                                                Selected Image
                                            </div>
                                            <div className="col-md-3">
                                                <img src={imageURL} style={{ maxWidth: '60%' }} />
                                            </div>
                                        </div>
                                    )}

                                    <div className="row pt-4">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            {loading ?
                                                <button className="btn btn-success" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 5 }}></span>
                                                    Submit
                                                </button> :
                                                <button type="submit" className="btn btn-success" >
                                                    Submit
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
            </form>

            {listOfExistingCarClasses.length > 0 ?
                <>
                    <hr style={{ height: '3px' }} />

                    <div className="row pt-2 mx-2">
                        <div className="col-md-10">
                            <h5 className=" mb-1" style={{ marginLeft: '-5px' }}>Existing Car Classes:</h5>

                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">SIPP Code</th>
                                        <th scope="col">Make</th>
                                        <th scope="col">Model</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Inventory</th>
                                        <th scope="col">Charges Per KM/Mile</th>
                                        <th scope="col">Steering Side</th>
                                        <th scope="col">Mileage</th>
                                        <th scope="col">CDW without Tax</th>
                                        <th scope="col">Deposit</th>
                                        <th scope="col">CDW Excess</th>
                                        <th scope="col">TP Excess</th>
                                        <th scope="col">Seat Count</th>
                                        <th scope="col">Door Count</th>
                                        <th scope="col">Luggage Count</th>
                                        <th scope="col">Vehicle Size</th>
                                        <th scope="col">Fleet Image</th>
                                        <th scope="col">Added By</th>
                                        <th scope="col">Edited By</th>
                                        {!(accessType === "View") &&
                                            <>
                                                <th scope="col">Edit</th>
                                                <th scope="col">Delete</th>
                                            </>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listOfExistingCarClasses.map((val, i) => (
                                            <tr>
                                                <td>{val.SIPP_code}</td>
                                                <td>{val.make}</td>
                                                <td>{val.model}</td>
                                                <td>{val.description}</td>
                                                <td>{val.inventory}</td>
                                                <td>{val.ceil }</td>
                                                <td>{val.steering_side}</td>
                                                <td>{val.mileage }</td>
                                                <td>{val.CDW}</td>
                                                <td>{val.deposit}</td>
                                                <td>{val.CDWExcess}</td>
                                                <td>{val.TPExcess}</td>
                                                <td>{val.seat_count}</td>
                                                <td>{val.door_count}</td>
                                                <td>{val.luggage_count}</td>
                                                <td>{val.vehicle_size}</td>
                                                <td style={{ width: "250px", position: "relative" }}>
                                                    {val.image_path && (
                                                        <>
                                                            <img 
                                                                src={baseURL + "/static/fleet_img/" + val.image_path} 
                                                                style={{ 
                                                                    width: '50px',
                                                                    height: '35px',
                                                                    // objectFit: 'cover',
                                                                    transition: 'transform 0.3s ease',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onMouseEnter={(e) => {
                                                                    e.target.style.transform = 'scale(5.5)';
                                                                    e.target.style.zIndex = '1000';
                                                                    e.target.style.position = 'absolute';
                                                                }}
                                                                onMouseLeave={(e) => {
                                                                    e.target.style.transform = 'scale(1)';
                                                                    e.target.style.zIndex = 'auto';
                                                                    e.target.style.position = 'static';
                                                                }}
                                                            />
                                                            <span 
                                                                style={{ 
                                                                    cursor: "pointer", 
                                                                    marginLeft: '10px',
                                                                    verticalAlign: 'top'
                                                                }} 
                                                                onClick={() => { downloadImage(baseURL + "/static/fleet_img/" + val.image_path, val.image_path); }}
                                                            >
                                                                <DownloadIcon />
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                                <td>{val.last_updated_by}</td>
                                                <td>{formatDate(new Date(val.last_updated_date))}</td>
                                                {
                                                    !(accessType === "View") &&
                                                    <>
                                                        <td><button className="btn" onClick={(e) => editCarClass(i)}><EditIcon /></button></td>
                                                        <td><button className="btn success" onClick={(e) => deleteCarClasses(i)}> <DeleteForeverIcon /></button></td>
                                                    </>
                                                }

                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </> : ''
            }
        </>
    );
}
